// Default: 500 - primary
$md-primary: (
    50 : #f8e7e7,
    100 : #edc4c2,
    200 : #e19d99,
    300 : #d47670,
    400 : #cb5852,
    500 : #c23b33,
    600 : #bc352e,
    700 : #b42d27,
    800 : #ac2620,
    900 : #9f1914,
    A100 : #ff99a8,
    A200 : #ff5c74,
    A400 : #ff425f,
    A700 : #ff3352,
    contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

// Default: 500 - accent
$md-secondary: (
    50 : #f6e9e9,
    100 : #e9c8c8,
    200 : #dba4a4,
    300 : #cd7f80,
    400 : #c26364,
    500 : #b74849,
    600 : #b04142,
    700 : #a73839,
    800 : #9f3031,
    900 : #902121,
    A100 : #ff94a5,
    A200 : #ff5772,
    A400 : #ff3d5d,
    A700 : #ff2e50,
    contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

// Default: 500 - warn
$md-warning: (
    50 : #fbede9,
    100 : #f5d1c7,
    200 : #efb3a2,
    300 : #e9957c,
    400 : #e47e60,
    500 : #df6744,
    600 : #db5f3e,
    700 : #d75435,
    800 : #d24a2d,
    900 : #ca391f,
    A100 : #ffe4e4,
    A200 : #ffa6a9,
    A400 : #ff8d90,
    A700 : #ff7e81,
    contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

// Default: 500
$md-dark: (
    50 : #e6e7e8,
    100 : #c0c2c5,
    200 : #969a9e,
    300 : #6b7177,
    400 : #4c5259,
    500 : #2c343c,
    600 : #272f36,
    700 : #21272e,
    800 : #1b2127,
    900 : #10151a,
    A100 : #15d8ff,
    A200 : #00b3d6,
    A400 : #009dbd,
    A700 : #0091ae,
    contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);


// Default: 500
$md-light: (
    50 : #fcfdfe,
    100 : #f8fbfb,
    200 : #f4f8f9,
    300 : #f0f5f7,
    400 : #ecf3f5,
    500 : #e9f1f3,
    600 : #e6eff1,
    700 : #e3edef,
    800 : #dfebed,
    900 : #d9e7ea,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
    contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

// Default: 500
$md-danger: (
    50 : #f1e3e6,
    100 : #ddb8c1,
    200 : #c78998,
    300 : #b0596e,
    400 : #9f364f,
    500 : #8e1230,
    600 : #86102b,
    700 : #7b0d24,
    800 : #710a1e,
    900 : #5f0513,
    A100 : #ff4487,
    A200 : #ff075f,
    A400 : #ec0054,
    A700 : #dd004f,
    contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

// Default: 500
$md-success: (
    50 : #e8f2ec,
    100 : #c5decf,
    200 : #9ec8af,
    300 : #77b28e,
    400 : #5aa276,
    500 : #3d915e,
    600 : #378956,
    700 : #2f7e4c,
    800 : #277442,
    900 : #1a6231,
    A100 : #72ff7d,
    A200 : #34ff44,
    A400 : #1bff2d,
    A700 : #0cff1f,
    contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

// Default: 500
$md-tertiary: (
    50 : #f1e5e5,
    100 : #ddbfbf,
    200 : #c79595,
    300 : #b06a6a,
    400 : #9f4a4a,
    500 : #8e2a2a,
    600 : #862525,
    700 : #7b1f1f,
    800 : #711919,
    900 : #5f0f0f,
    A100 : #ff5277,
    A200 : #ff1547,
    A400 : #fa0036,
    A700 : #eb0033,
    contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

// Default: 500
$md-white: (
    50 : #ffffff,
    100 : #ffffff,
    200 : #ffffff,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
    contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

// Default: 500
$md-gray-10: (
    50 : #fefefe,
    100 : #fcfdfd,
    200 : #fbfbfc,
    300 : #f9f9fa,
    400 : #f7f8f9,
    500 : #f6f7f8,
    600 : #f5f6f7,
    700 : #f3f5f6,
    800 : #f2f3f5,
    900 : #eff1f3,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
    contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);


// Default: 500
$md-gray-20: (
    50 : #f4f6f7,
    100 : #e4e8eb,
    200 : #d3d8de,
    300 : #c1c8d1,
    400 : #b3bdc7,
    500 : #a6b1bd,
    600 : #9eaab7,
    700 : #95a1ae,
    800 : #8b98a6,
    900 : #7b8898,
    A100 : #fbfdff,
    A200 : #c0e2fc,
    A400 : #a4d7ff,
    A700 : #94d1ff,
    contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

// Default: 500
$md-gray-25: (
    50 : #eceef0,
    100 : #cfd4d9,
    200 : #b0b8c0,
    300 : #909ba7,
    400 : #788594,
    500 : #607081,
    600 : #586879,
    700 : #4e5d6e,
    800 : #445364,
    900 : #334151,
    A100 : #68caff,
    A200 : #2bb5ff,
    A400 : #12acff,
    A700 : #02a6ff,
    contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #ffffff,
    )
);

// Default: 500
$md-gray-50: (
    50 : #fdfdfd,
    100 : #fafafa,
    200 : #f6f6f6,
    300 : #f2f2f2,
    400 : #f0f0f0,
    500 : #ededed,
    600 : #ebebeb,
    700 : #e8e8e8,
    800 : #e5e5e5,
    900 : #e0e0e0,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
    contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

// Default: 500
$md-gray100: (
    50 : #f6f6f6,
    100 : #e7e8e8,
    200 : #d8d9d9,
    300 : #c8c9ca,
    400 : #bcbebe,
    500 : #b0b2b3,
    600 : #a9abac,
    700 : #a0a2a3,
    800 : #97999a,
    900 : #878a8b,
    A100 : #fcfeff,
    A200 : #c4f3f9,
    A400 : #a5f4ff,
    A700 : #96f3ff,
    contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

// Default: 500
$md-darkred: (
    50 : #ede1e0,
    100 : #d3b4b3,
    200 : #b68380,
    300 : #98514d,
    400 : #822b26,
    500 : #6c0600,
    600 : #640500,
    700 : #590400,
    800 : #4f0300,
    900 : #3d0200,
    A100 : #ff2570,
    A200 : #e7004f,
    A400 : #cd0046,
    A700 : #be0041,
    contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #ffffff,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

// Default: 500
$md-skybluedark: (
    50 : #eef4f7,
    100 : #d4e5eb,
    200 : #b7d3de,
    300 : #9ac1d0,
    400 : #84b4c6,
    500 : #6ea7bc,
    600 : #669fb6,
    700 : #5b96ad,
    800 : #518ca5,
    900 : #3f7c97,
    A100 : #b7f2ff,
    A200 : #7ae8ff,
    A400 : #60e3ff,
    A700 : #51e1ff,
    contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

// Default: 500
$md-rosedark: (
    50 : #fffbfb,
    100 : #fff6f5,
    200 : #fff0ef,
    300 : #ffe9e9,
    400 : #ffe5e4,
    500 : #ffe0df,
    600 : #ffdcdb,
    700 : #ffd8d7,
    800 : #ffd3d2,
    900 : #ffcbca,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
    contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

// Default: 500
$md-lightred: (
    50 : #fbf1f1,
    100 : #f6dddc,
    200 : #f0c7c5,
    300 : #e9b0ad,
    400 : #e59f9c,
    500 : #e08e8a,
    600 : #dc8682,
    700 : #d87b77,
    800 : #d3716d,
    900 : #cb5f5a,
    A100 : #ffffff,
    A200 : #ffd3d6,
    A400 : #ffb9bf,
    A700 : #ffaab1,
    contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

// Default: 500
$md-pastelgreen: (
    50 : #f7fbf5,
    100 : #eaf4e7,
    200 : #dcedd7,
    300 : #cee5c7,
    400 : #c4e0bb,
    500 : #b9daaf,
    600 : #b2d6a8,
    700 : #aad09f,
    800 : #a2cb96,
    900 : #93c286,
    A100 : #ffffff,
    A200 : #f7fff3,
    A400 : #e5ffd9,
    A700 : #dbffca,
    contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

// Default: 500
$md-pastelorange: (
    50 : #fdfaf8,
    100 : #faf2ef,
    200 : #f6e9e4,
    300 : #f2e0d9,
    400 : #f0dad0,
    500 : #edd3c8,
    600 : #ebcec2,
    700 : #e8c8bb,
    800 : #e5c2b4,
    900 : #e0b7a7,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
    contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

// Default: 500
$md-salmon: (
    50 : #fff8f8,
    100 : #ffedee,
    200 : #ffe2e3,
    300 : #ffd6d7,
    400 : #ffcdcf,
    500 : #ffc4c6,
    600 : #ffbec0,
    700 : #ffb6b9,
    800 : #ffafb1,
    900 : #ffa2a4,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
    contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

// Default: 500
$md-pinkdark: (
    50 : #fff1f1,
    100 : #ffdcdd,
    200 : #ffc5c7,
    300 : #ffaeb0,
    400 : #ff9c9f,
    500 : #ff8b8e,
    600 : #ff8386,
    700 : #ff787b,
    800 : #ff6e71,
    900 : #ff5b5f,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffeaec,
    A700 : #ffdadf,
    contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

// Default: 500
$md-light-reddish: (
    50 : #f2e1e0,
    100 : #dfb5b3,
    200 : #c98480,
    300 : #b3524d,
    400 : #a32d26,
    500 : #930800,
    600 : #8b0700,
    700 : #800600,
    800 : #760400,
    900 : #640200,
    A100 : #ff4979,
    A200 : #ff0c4c,
    A400 : #f20040,
    A700 : #e2003c,
    contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

// Default: 500
$md-black: (
    50 : #e0e0e0,
    100 : #b3b3b3,
    200 : #808080,
    300 : #4d4d4d,
    400 : #262626,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #7a7a7a,
    A200 : #5c5c5c,
    A400 : #4f4f4f,
    A700 : #474747,
    contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #ffffff,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

// Default: 500
$md-white: (
    50 : #ffffff,
    100 : #ffffff,
    200 : #ffffff,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
    contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

// Default: 500
$md-yellow: (
    50 : #fefce9,
    100 : #fdf6c8,
    200 : #fbf1a4,
    300 : #f9eb80,
    400 : #f8e664,
    500 : #f7e249,
    600 : #f6df42,
    700 : #f5da39,
    800 : #f3d631,
    900 : #f1cf21,
    A100 : #ffffff,
    A200 : #fff2d5,
    A400 : #ffebbb,
    A700 : #ffe6ac,
    contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

// Default: 500
$md-tangerine: (
    50 : #fdf6e4,
    100 : #fbe9bb,
    200 : #f9da8e,
    300 : #f6cb61,
    400 : #f4c03f,
    500 : #f2b51d,
    600 : #f0ae1a,
    700 : #eea515,
    800 : #ec9d11,
    900 : #e88d0a,
    A100 : #fffefd,
    A200 : #ffd9bf,
    A400 : #ffcaa6,
    A700 : #ffc197,
    contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

// Default: 500
$md-redmond: (
    50 : #eae1e0,
    100 : #cbb3b3,
    200 : #a88180,
    300 : #854f4d,
    400 : #6b2926,
    500 : #510300,
    600 : #4a0300,
    700 : #400200,
    800 : #370200,
    900 : #270100,
    A100 : #ff117a,
    A200 : #d3005d,
    A400 : #ba0051,
    A700 : #aa004b,
    contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #ffffff,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);


@use '@angular/material' as mat;

@include mat.core();

$plv-primary: mat.define-palette($md-primary, 500);
$plv-accent: mat.define-palette($md-secondary, 500);
$plv-warn: mat.define-palette($md-warning, 500);

$app-theme: mat.define-light-theme((color: (primary: $plv-primary,
                accent: $plv-accent,
                warn: $plv-warn,
            )));

@include mat.all-component-themes($app-theme);

.mat-dark {
    color: mat.get-contrast-color-from-palette($md-dark, 500) !important;
    background-color: mat.get-color-from-palette($md-dark, 500) !important;
}

.mat-dark[disabled] {
    background-color: rgba(0, 0, 0, 0.12) !important;
}

.mat-dark[mat-icon-button] {
    background-color: mat.get-contrast-color-from-palette($md-dark, 500) !important;
    color: mat.get-color-from-palette($md-dark, 500) !important;
}

.mat-dark[mat-stroked-button] {
    background-color: mat.get-contrast-color-from-palette($md-dark, 500) !important;
    color: mat.get-color-from-palette($md-dark, 500) !important;
}

.mat-light {
    color: mat.get-contrast-color-from-palette($md-light, 500) !important;
    background-color: mat.get-color-from-palette($md-light, 500) !important;
}

.mat-light[disabled] {
    background-color: rgba(0, 0, 0, 0.12) !important;
}

.mat-light[mat-icon-button] {
    // background-color: mat.get-contrast-color-from-palette($md-light, 500) !important;
    background-color: mat.get-color-from-palette($md-white, 500) !important;
    color: mat.get-color-from-palette($md-light, 500) !important;
}

.mat-light[mat-stroked-button] {
    // background-color: mat.get-contrast-color-from-palette($md-light, 500) !important;
    background-color: mat.get-color-from-palette($md-white, 500) !important;
    color: mat.get-color-from-palette($md-light, 500) !important;
}

.mat-danger {
    color: mat.get-contrast-color-from-palette($md-danger, 500) !important;
    background-color: mat.get-color-from-palette($md-danger, 500) !important;
}

.mat-danger[disabled] {
    background-color: rgba(0, 0, 0, 0.12) !important;
}

.mat-danger[mat-icon-button] {
    background-color: mat.get-contrast-color-from-palette($md-danger, 500) !important;
    color: mat.get-color-from-palette($md-danger, 500) !important;
}

.mat-danger[mat-stroked-button] {
    background-color: mat.get-contrast-color-from-palette($md-danger, 500) !important;
    color: mat.get-color-from-palette($md-danger, 500) !important;
}

.mat-success {
    color: mat.get-contrast-color-from-palette($md-success, 500) !important;
    background-color: mat.get-color-from-palette($md-success, 500) !important;
}

.mat-success[disabled] {
    background-color: rgba(0, 0, 0, 0.12) !important;
}

.mat-success[mat-icon-button] {
    background-color: mat.get-contrast-color-from-palette($md-success, 500) !important;
    color: mat.get-color-from-palette($md-success, 500) !important;
}

.mat-success[mat-stroked-button] {
    background-color: mat.get-contrast-color-from-palette($md-success, 500) !important;
    color: mat.get-color-from-palette($md-success, 500) !important;
}

.mat-tertiary {
    color: mat.get-contrast-color-from-palette($md-tertiary, 500) !important;
    background-color: mat.get-color-from-palette($md-tertiary, 500) !important;
}

.mat-tertiary[disabled] {
    background-color: rgba(0, 0, 0, 0.12) !important;
}

.mat-tertiary[mat-icon-button] {
    background-color: mat.get-contrast-color-from-palette($md-tertiary, 500) !important;
    color: mat.get-color-from-palette($md-tertiary, 500) !important;
}

.mat-tertiary[mat-stroked-button] {
    background-color: mat.get-contrast-color-from-palette($md-tertiary, 500) !important;
    color: mat.get-color-from-palette($md-tertiary, 500) !important;
}

.mat-gray-10 {
    color: mat.get-contrast-color-from-palette($md-gray-10, 500) !important;
    background-color: mat.get-color-from-palette($md-gray-10, 500) !important;
}

.mat-gray-10[disabled] {
    background-color: rgba(0, 0, 0, 0.12) !important;
}

.mat-gray-10[mat-icon-button] {
    // background-color: mat.get-contrast-color-from-palette($md-gray-10, 500) !important;
    background-color: mat.get-color-from-palette($md-white, 500) !important;
    color: mat.get-color-from-palette($md-gray-10, 500) !important;
}

.mat-gray-10[mat-stroked-button] {
    // background-color: mat.get-contrast-color-from-palette($md-gray-10, 500) !important;
    background-color: mat.get-color-from-palette($md-white, 500) !important;
    color: mat.get-color-from-palette($md-gray-10, 500) !important;
}

.mat-gray-20 {
    color: mat.get-contrast-color-from-palette($md-gray-20, 500) !important;
    background-color: mat.get-color-from-palette($md-gray-20, 500) !important;
}

.mat-gray-20[disabled] {
    background-color: rgba(0, 0, 0, 0.12) !important;
}

.mat-gray-20[mat-icon-button] {
    // background-color: mat.get-contrast-color-from-palette($md-gray-20, 500) !important;
    background-color: mat.get-color-from-palette($md-white, 500) !important;
    color: mat.get-color-from-palette($md-gray-20, 500) !important;
}

.mat-gray-20[mat-stroked-button] {
    // background-color: mat.get-contrast-color-from-palette($md-gray-20, 500) !important;
    background-color: mat.get-color-from-palette($md-white, 500) !important;
    color: mat.get-color-from-palette($md-gray-20, 500) !important;
}

.mat-gray-25 {
    color: mat.get-contrast-color-from-palette($md-gray-25, 500) !important;
    background-color: mat.get-color-from-palette($md-gray-25, 500) !important;
}

.mat-gray-25[disabled] {
    background-color: rgba(0, 0, 0, 0.12) !important;
}

.mat-gray-25[mat-icon-button] {
    background-color: mat.get-contrast-color-from-palette($md-gray-25, 500) !important;
    color: mat.get-color-from-palette($md-gray-25, 500) !important;
}

.mat-gray-25[mat-stroked-button] {
    background-color: mat.get-contrast-color-from-palette($md-gray-25, 500) !important;
    color: mat.get-color-from-palette($md-gray-25, 500) !important;
}

.mat-gray-50 {
    color: mat.get-contrast-color-from-palette($md-gray-50, 500) !important;
    background-color: mat.get-color-from-palette($md-gray-50, 500) !important;
}

.mat-gray-50[disabled] {
    background-color: rgba(0, 0, 0, 0.12) !important;
}

.mat-gray-50[mat-icon-button] {
    // background-color: mat.get-contrast-color-from-palette($md-gray-50, 500) !important;
    background-color: mat.get-color-from-palette($md-white, 500) !important;
    color: mat.get-color-from-palette($md-gray-50, 500) !important;
}

.mat-gray-50[mat-stroked-button] {
    // background-color: mat.get-contrast-color-from-palette($md-gray-50, 500) !important;
    background-color: mat.get-color-from-palette($md-white, 500) !important;
    color: mat.get-color-from-palette($md-gray-50, 500) !important;
}

.mat-gray100 {
    color: mat.get-contrast-color-from-palette($md-gray100, 500) !important;
    background-color: mat.get-color-from-palette($md-gray100, 500) !important;
}

.mat-gray100[disabled] {
    background-color: rgba(0, 0, 0, 0.12) !important;
}

.mat-gray100[mat-icon-button] {
    // background-color: mat.get-contrast-color-from-palette($md-gray100, 500) !important;
    background-color: mat.get-color-from-palette($md-white, 500) !important;
    color: mat.get-color-from-palette($md-gray100, 500) !important;
}

.mat-gray100[mat-stroked-button] {
    // background-color: mat.get-contrast-color-from-palette($md-gray100, 500) !important;
    background-color: mat.get-color-from-palette($md-white, 500) !important;
    color: mat.get-color-from-palette($md-gray100, 500) !important;
}

.mat-darkred {
    color: mat.get-contrast-color-from-palette($md-darkred, 500) !important;
    background-color: mat.get-color-from-palette($md-darkred, 500) !important;
}

.mat-darkred[disabled] {
    background-color: rgba(0, 0, 0, 0.12) !important;
}

.mat-darkred[mat-icon-button] {
    background-color: mat.get-contrast-color-from-palette($md-darkred, 500) !important;
    color: mat.get-color-from-palette($md-darkred, 500) !important;
}

.mat-darkred[mat-stroked-button] {
    background-color: mat.get-contrast-color-from-palette($md-darkred, 500) !important;
    color: mat.get-color-from-palette($md-darkred, 500) !important;
}

.mat-skybluedark {
    color: mat.get-contrast-color-from-palette($md-skybluedark, 500) !important;
    background-color: mat.get-color-from-palette($md-skybluedark, 500) !important;
}

.mat-skybluedark[disabled] {
    background-color: rgba(0, 0, 0, 0.12) !important;
}

.mat-skybluedark[mat-icon-button] {
    // background-color: mat.get-contrast-color-from-palette($md-skybluedark, 500) !important;
    background-color: mat.get-color-from-palette($md-white, 500) !important;
    color: mat.get-color-from-palette($md-skybluedark, 500) !important;
}

.mat-skybluedark[mat-stroked-button] {
    // background-color: mat.get-contrast-color-from-palette($md-skybluedark, 500) !important;
    background-color: mat.get-color-from-palette($md-white, 500) !important;
    color: mat.get-color-from-palette($md-skybluedark, 500) !important;
}

.mat-rosedark {
    color: mat.get-contrast-color-from-palette($md-rosedark, 500) !important;
    background-color: mat.get-color-from-palette($md-rosedark, 500) !important;
}

.mat-rosedark[disabled] {
    background-color: rgba(0, 0, 0, 0.12) !important;
}

.mat-rosedark[mat-icon-button] {
    // background-color: mat.get-contrast-color-from-palette($md-rosedark, 500) !important;
    background-color: mat.get-color-from-palette($md-white, 500) !important;
    color: mat.get-color-from-palette($md-rosedark, 500) !important;
}

.mat-rosedark[mat-stroked-button] {
    // background-color: mat.get-contrast-color-from-palette($md-rosedark, 500) !important;
    background-color: mat.get-color-from-palette($md-white, 500) !important;
    color: mat.get-color-from-palette($md-rosedark, 500) !important;
}

.mat-lightred {
    color: mat.get-contrast-color-from-palette($md-lightred, 500) !important;
    background-color: mat.get-color-from-palette($md-lightred, 500) !important;
}

.mat-lightred[disabled] {
    background-color: rgba(0, 0, 0, 0.12) !important;
}

.mat-lightred[mat-icon-button] {
    // background-color: mat.get-contrast-color-from-palette($md-lightred, 500) !important;
    background-color: mat.get-color-from-palette($md-white, 500) !important;
    color: mat.get-color-from-palette($md-lightred, 500) !important;
}

.mat-lightred[mat-stroked-button] {
    // background-color: mat.get-contrast-color-from-palette($md-lightred, 500) !important;
    background-color: mat.get-color-from-palette($md-white, 500) !important;
    color: mat.get-color-from-palette($md-lightred, 500) !important;
}

.mat-pastelgreen {
    color: mat.get-contrast-color-from-palette($md-pastelgreen, 500) !important;
    background-color: mat.get-color-from-palette($md-pastelgreen, 500) !important;
}

.mat-pastelgreen[disabled] {
    background-color: rgba(0, 0, 0, 0.12) !important;
}

.mat-pastelgreen[mat-icon-button] {
    // background-color: mat.get-contrast-color-from-palette($md-pastelgreen, 500) !important;
    background-color: mat.get-color-from-palette($md-white, 500) !important;
    color: mat.get-color-from-palette($md-pastelgreen, 500) !important;
}

.mat-pastelgreen[mat-stroked-button] {
    // background-color: mat.get-contrast-color-from-palette($md-pastelgreen, 500) !important;
    background-color: mat.get-color-from-palette($md-white, 500) !important;
    color: mat.get-color-from-palette($md-pastelgreen, 500) !important;
}

.mat-pastelorange {
    color: mat.get-contrast-color-from-palette($md-pastelorange, 500) !important;
    background-color: mat.get-color-from-palette($md-pastelorange, 500) !important;
}

.mat-pastelorange[disabled] {
    background-color: rgba(0, 0, 0, 0.12) !important;
}

.mat-pastelorange[mat-icon-button] {
    // background-color: mat.get-contrast-color-from-palette($md-pastelorange, 500) !important;
    background-color: mat.get-color-from-palette($md-white, 500) !important;
    color: mat.get-color-from-palette($md-pastelorange, 500) !important;
}

.mat-pastelorange[mat-stroked-button] {
    // background-color: mat.get-contrast-color-from-palette($md-pastelorange, 500) !important;
    background-color: mat.get-color-from-palette($md-white, 500) !important;
    color: mat.get-color-from-palette($md-pastelorange, 500) !important;
}

.mat-salmon {
    color: mat.get-contrast-color-from-palette($md-salmon, 500) !important;
    background-color: mat.get-color-from-palette($md-salmon, 500) !important;
}

.mat-salmon[disabled] {
    background-color: rgba(0, 0, 0, 0.12) !important;
}

.mat-salmon[mat-icon-button] {
    // background-color: mat.get-contrast-color-from-palette($md-salmon, 500) !important;
    background-color: mat.get-color-from-palette($md-white, 500) !important;
    color: mat.get-color-from-palette($md-salmon, 500) !important;
}

.mat-salmon[mat-stroked-button] {
    // background-color: mat.get-contrast-color-from-palette($md-salmon, 500) !important;
    background-color: mat.get-color-from-palette($md-white, 500) !important;
    color: mat.get-color-from-palette($md-salmon, 500) !important;
}

.mat-pinkdark {
    color: mat.get-contrast-color-from-palette($md-pinkdark, 500) !important;
    background-color: mat.get-color-from-palette($md-pinkdark, 500) !important;
}

.mat-pinkdark[disabled] {
    background-color: rgba(0, 0, 0, 0.12) !important;
}

.mat-pinkdark[mat-icon-button] {
    // background-color: mat.get-contrast-color-from-palette($md-pinkdark, 500) !important;
    background-color: mat.get-color-from-palette($md-white, 500) !important;
    color: mat.get-color-from-palette($md-pinkdark, 500) !important;
}

.mat-pinkdark[mat-stroked-button] {
    // background-color: mat.get-contrast-color-from-palette($md-pinkdark, 500) !important;
    background-color: mat.get-color-from-palette($md-white, 500) !important;
    color: mat.get-color-from-palette($md-pinkdark, 500) !important;
}

.mat-light-reddish {
    color: mat.get-contrast-color-from-palette($md-light-reddish, 500) !important;
    background-color: mat.get-color-from-palette($md-light-reddish, 500) !important;
}

.mat-light-reddish[disabled] {
    background-color: rgba(0, 0, 0, 0.12) !important;
}

.mat-light-reddish[mat-icon-button] {
    background-color: mat.get-contrast-color-from-palette($md-light-reddish, 500) !important;
    color: mat.get-color-from-palette($md-light-reddish, 500) !important;
}

.mat-light-reddish[mat-stroked-button] {
    background-color: mat.get-contrast-color-from-palette($md-light-reddish, 500) !important;
    color: mat.get-color-from-palette($md-light-reddish, 500) !important;
}

.mat-black {
    color: mat.get-contrast-color-from-palette($md-black, 500) !important;
    background-color: mat.get-color-from-palette($md-black, 500) !important;
}

.mat-black[disabled] {
    background-color: rgba(0, 0, 0, 0.12) !important;
}

.mat-black[mat-icon-button] {
    background-color: mat.get-contrast-color-from-palette($md-black, 500) !important;
    color: mat.get-color-from-palette($md-black, 500) !important;
}

.mat-black[mat-stroked-button] {
    background-color: mat.get-contrast-color-from-palette($md-black, 500) !important;
    color: mat.get-color-from-palette($md-black, 500) !important;
}

.mat-white {
    color: mat.get-contrast-color-from-palette($md-white, 500) !important;
    background-color: mat.get-color-from-palette($md-white, 500) !important;
}

.mat-white[disabled] {
    background-color: rgba(0, 0, 0, 0.12) !important;
}

.mat-white[mat-icon-button] {
    background-color: mat.get-contrast-color-from-palette($md-white, 500) !important;
    color: mat.get-color-from-palette($md-white, 500) !important;
}

.mat-white[mat-stroked-button] {
    background-color: mat.get-contrast-color-from-palette($md-white, 500) !important;
    color: mat.get-color-from-palette($md-white, 500) !important;
}

.mat-yellow {
    color: mat.get-contrast-color-from-palette($md-yellow, 500) !important;
    background-color: mat.get-color-from-palette($md-yellow, 500) !important;
}

.mat-yellow[disabled] {
    background-color: rgba(0, 0, 0, 0.12) !important;
}

.mat-yellow[mat-icon-button] {
    // background-color: mat.get-contrast-color-from-palette($md-yellow, 500) !important;
    background-color: mat.get-color-from-palette($md-white, 500) !important;
    color: mat.get-color-from-palette($md-yellow, 500) !important;
}

.mat-yellow[mat-stroked-button] {
    // background-color: mat.get-contrast-color-from-palette($md-yellow, 500) !important;
    background-color: mat.get-color-from-palette($md-white, 500) !important;
    color: mat.get-color-from-palette($md-yellow, 500) !important;
}

.mat-tangerine {
    color: mat.get-contrast-color-from-palette($md-tangerine, 500) !important;
    background-color: mat.get-color-from-palette($md-tangerine, 500) !important;
}

.mat-tangerine[disabled] {
    background-color: rgba(0, 0, 0, 0.12) !important;
}

.mat-tangerine[mat-icon-button] {
    // background-color: mat.get-contrast-color-from-palette($md-tangerine, 500) !important;
    background-color: mat.get-color-from-palette($md-white, 500) !important;
    color: mat.get-color-from-palette($md-tangerine, 500) !important;
}

.mat-tangerine[mat-stroked-button] {
    // background-color: mat.get-contrast-color-from-palette($md-tangerine, 500) !important;
    background-color: mat.get-color-from-palette($md-white, 500) !important;
    color: mat.get-color-from-palette($md-tangerine, 500) !important;
}

.mat-redmond {
    color: mat.get-contrast-color-from-palette($md-redmond, 500) !important;
    background-color: mat.get-color-from-palette($md-redmond, 500) !important;
}

.mat-redmond[disabled] {
    background-color: rgba(0, 0, 0, 0.12) !important;
}

.mat-redmond[mat-icon-button] {
    background-color: mat.get-contrast-color-from-palette($md-redmond, 500) !important;
    color: mat.get-color-from-palette($md-redmond, 500) !important;
}

.mat-redmond[mat-stroked-button] {
    background-color: mat.get-contrast-color-from-palette($md-redmond, 500) !important;
    color: mat.get-color-from-palette($md-redmond, 500) !important;
}