/* ******************* Accessibility ******************* */

@mixin font-sizes($size) {
  &.xsmall {
    font-size: $size - 0.2rem;
    line-height: $size + 0.4rem;
  }
  &.small {
    font-size: $size - 0.1rem;
    line-height: $size + 0.5rem;
  }
  &.medium {
    font-size: $size;
    line-height: $size + 0.6rem;
  }
  &.large {
    font-size: $size + 0.1rem;
    line-height: $size + 0.7rem;
  }
  &.xlarge {
    font-size: $size + 0.2rem;
    line-height: $size + 0.8rem;
  }
}

@mixin font-sizes-dynamic-text($size-h1, $size-p, $size-li) {
  &.xsmall {
    p {
      font-size: $size-p - 0.2rem;
      line-height: $size-p + 0.4rem;
    }
    h1 {
      font-size: $size-h1 - 0.2rem;
      line-height: $size-h1 + 0.4rem;
    }
    ul li, ol li, td, th {
      font-size: $size-li - 0.2rem;
      line-height: $size-li + 0.4rem;
    }
  }
  &.small {
    p {
      font-size: $size-p - 0.1rem;
      line-height: $size-p + 0.5rem;
    }
    h1 {
      font-size: $size-h1 - 0.1rem;
      line-height: $size-h1 + 0.5rem;
    }
    ul li, ol li, td, th {
      font-size: $size-li - 0.1rem;
      line-height: $size-li + 0.5rem;
    }
  }
  &.medium {
    p {
      font-size: $size-p;
      line-height: $size-p + 0.6rem;
    }
    h1 {
      font-size: $size-h1;
      line-height: $size-h1 + 0.6rem;
    }
    ul li, ol li, td, th {
      font-size: $size-li;
      line-height: $size-li + 0.6rem;
    }
  }
  &.large {
    p {
      font-size: $size-p + 0.1rem;
      line-height: $size-p + 0.7rem;
    }
    h1 {
      font-size: $size-h1 + 0.1rem;
      line-height: $size-h1 + 0.7rem;
    }
    ul li, ol li, td, th {
      font-size: $size-li + 0.1rem;
      line-height: $size-li + 0.7rem;
    }
  }
  &.xlarge {
    p {
      font-size: $size-p + 0.2rem;
      line-height: $size-p + 0.8rem;
    }
    h1 {
      font-size: $size-h1 + 0.2rem;
      line-height: $size-h1 + 0.8rem;
    }
    ul li, ol li, td, th {
      font-size: $size-li + 0.2rem;
      line-height: $size-li + 0.8rem;
    }
  }
}

/* ******************* Fund Colors ******************* */

@mixin fund-color-risk($fund) {
  .fund-#{$fund}-risk {
    color: var(--ion-color-fund-#{$fund});
  }
  .fund-#{$fund}-background {
    background-color: var(--ion-color-fund-#{$fund});
  }
}
/* ******************* Social network Colors ******************* */
@mixin social-network($name) {
  &.#{$name} {
    background-color: var(--ion-color-#{$name});
  }
}

/* ******************* Info Banner ******************* */

@mixin highlighted-lines($highlighted, $normal) {
  &.highlighted-first {
    .first-line {
      @include font-sizes($highlighted);
    }
    .second-line {
      @include font-sizes($normal);
    }
  }

  &.highlighted-second {
    .first-line {
      @include font-sizes($normal);
    }
    .second-line {
      @include font-sizes($highlighted);
    }
  }
}
