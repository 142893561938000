:root {

  --ion-color-facebook: #3B5998;
  --ion-color-facebook-rgb: 59, 89, 152;
  --ion-color-facebook-contrast: #ffffff;
  --ion-color-facebook-contrast-rgb: 255, 255, 255;
  --ion-color-facebook-shade: #344e86;
  --ion-color-facebook-tint: #4f6aa2;

  --ion-color-instagram: #CE4EAD;
  --ion-color-instagram-rgb: 206, 78, 173;
  --ion-color-instagram-contrast: #ffffff;
  --ion-color-instagram-contrast-rgb: 255, 255, 255;
  --ion-color-instagram-shade: #b54598;
  --ion-color-instagram-tint: #d360b5;

  --ion-color-linkedin: #2977C9;
  --ion-color-linkedin-rgb: 41, 119, 201;
  --ion-color-linkedin-contrast: #ffffff;
  --ion-color-linkedin-contrast-rgb: 255, 255, 255;
  --ion-color-linkedin-shade: #2469b1;
  --ion-color-linkedin-tint: #3e85ce;

  --ion-color-pinterest: #d32c2f;
  --ion-color-pinterest-rgb: 211, 44, 47;
  --ion-color-pinterest-contrast: #ffffff;
  --ion-color-pinterest-contrast-rgb: 255, 255, 255;
  --ion-color-pinterest-shade: #ba2729;
  --ion-color-pinterest-tint: #d74144;

  --ion-color-telegram: #509fd8;
  --ion-color-telegram-rgb: 80, 159, 216;
  --ion-color-telegram-contrast: #000000;
  --ion-color-telegram-contrast-rgb: 0, 0, 0;
  --ion-color-telegram-shade: #468cbe;
  --ion-color-telegram-tint: #62a9dc;

  --ion-color-twitch: #9f74f7;
  --ion-color-twitch-rgb: 159, 116, 247;
  --ion-color-twitch-contrast: #000000;
  --ion-color-twitch-contrast-rgb: 0, 0, 0;
  --ion-color-twitch-shade: #8c66d9;
  --ion-color-twitch-tint: #a982f8;

  --ion-color-twitter: #00A2F9;
  --ion-color-twitter-rgb: 0, 162, 249;
  --ion-color-twitter-contrast: #ffffff;
  --ion-color-twitter-contrast-rgb: 255, 255, 255;
  --ion-color-twitter-shade: #008fdb;
  --ion-color-twitter-tint: #1aabfa;

  --ion-color-whatsapp: #25d366;
  --ion-color-whatsapp-rgb: 37, 211, 102;
  --ion-color-whatsapp-contrast: #000000;
  --ion-color-whatsapp-contrast-rgb: 0, 0, 0;
  --ion-color-whatsapp-shade: #21ba5a;
  --ion-color-whatsapp-tint: #3bd775;

  --ion-color-youtube: #c23b33;
  --ion-color-youtube-rgb: 194, 59, 51;
  --ion-color-youtube-contrast: #ffffff;
  --ion-color-youtube-contrast-rgb: 255, 255, 255;
  --ion-color-youtube-shade: #ab342d;
  --ion-color-youtube-tint: #c84f47;
}

.ion-color-facebook {
  --ion-color-base: var(--ion-color-facebook);
  --ion-color-base-rgb: var(--ion-color-facebook-rgb);
  --ion-color-contrast: var(--ion-color-facebook-contrast);
  --ion-color-contrast-rgb: var(--ion-color-facebook-contrast-rgb);
  --ion-color-shade: var(--ion-color-facebook-shade);
  --ion-color-tint: var(--ion-color-facebook-tint);
}

.ion-color-instagram {
  --ion-color-base: var(--ion-color-instagram);
  --ion-color-base-rgb: var(--ion-color-instagram-rgb);
  --ion-color-contrast: var(--ion-color-instagram-contrast);
  --ion-color-contrast-rgb: var(--ion-color-instagram-contrast-rgb);
  --ion-color-shade: var(--ion-color-instagram-shade);
  --ion-color-tint: var(--ion-color-instagram-tint);
}

.ion-color-linkedin {
  --ion-color-base: var(--ion-color-linkedin);
  --ion-color-base-rgb: var(--ion-color-linkedin-rgb);
  --ion-color-contrast: var(--ion-color-linkedin-contrast);
  --ion-color-contrast-rgb: var(--ion-color-linkedin-contrast-rgb);
  --ion-color-shade: var(--ion-color-linkedin-shade);
  --ion-color-tint: var(--ion-color-linkedin-tint);
}

.ion-color-pinterest {
  --ion-color-base: var(--ion-color-pinterest);
  --ion-color-base-rgb: var(--ion-color-pinterest-rgb);
  --ion-color-contrast: var(--ion-color-pinterest-contrast);
  --ion-color-contrast-rgb: var(--ion-color-pinterest-contrast-rgb);
  --ion-color-shade: var(--ion-color-pinterest-shade);
  --ion-color-tint: var(--ion-color-pinterest-tint);
}

.ion-color-telegram {
  --ion-color-base: var(--ion-color-telegram);
  --ion-color-base-rgb: var(--ion-color-telegram-rgb);
  --ion-color-contrast: var(--ion-color-telegram-contrast);
  --ion-color-contrast-rgb: var(--ion-color-telegram-contrast-rgb);
  --ion-color-shade: var(--ion-color-telegram-shade);
  --ion-color-tint: var(--ion-color-telegram-tint);
}

.ion-color-twitch {
  --ion-color-base: var(--ion-color-twitch);
  --ion-color-base-rgb: var(--ion-color-twitch-rgb);
  --ion-color-contrast: var(--ion-color-twitch-contrast);
  --ion-color-contrast-rgb: var(--ion-color-twitch-contrast-rgb);
  --ion-color-shade: var(--ion-color-twitch-shade);
  --ion-color-tint: var(--ion-color-twitch-tint);
}

.ion-color-twitter {
  --ion-color-base: var(--ion-color-twitter);
  --ion-color-base-rgb: var(--ion-color-twitter-rgb);
  --ion-color-contrast: var(--ion-color-twitter-contrast);
  --ion-color-contrast-rgb: var(--ion-color-twitter-contrast-rgb);
  --ion-color-shade: var(--ion-color-twitter-shade);
  --ion-color-tint: var(--ion-color-twitter-tint);
}

.ion-color-whatsapp {
  --ion-color-base: var(--ion-color-whatsapp);
  --ion-color-base-rgb: var(--ion-color-whatsapp-rgb);
  --ion-color-contrast: var(--ion-color-whatsapp-contrast);
  --ion-color-contrast-rgb: var(--ion-color-whatsapp-contrast-rgb);
  --ion-color-shade: var(--ion-color-whatsapp-shade);
  --ion-color-tint: var(--ion-color-whatsapp-tint);
}

.ion-color-youtube {
  --ion-color-base: var(--ion-color-youtube);
  --ion-color-base-rgb: var(--ion-color-youtube-rgb);
  --ion-color-contrast: var(--ion-color-youtube-contrast);
  --ion-color-contrast-rgb: var(--ion-color-youtube-contrast-rgb);
  --ion-color-shade: var(--ion-color-youtube-shade);
  --ion-color-tint: var(--ion-color-youtube-tint);
}