@import 'https://fonts.googleapis.com/icon?family=Material+Icons';
// @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');

@font-face {
    font-family: "Roboto-Black";
    src: url("../assets/fonts/Roboto/Roboto-Black.ttf") format("opentype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Roboto-BlackItalic";
    src: url("../assets/fonts/Roboto/Roboto-BlackItalic.ttf") format("opentype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Roboto-Bold";
    src: url("../assets/fonts/Roboto/Roboto-Bold.ttf") format("opentype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Roboto-BoldItalic";
    src: url("../assets/fonts/Roboto/Roboto-BoldItalic.ttf") format("opentype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Roboto-Italic";
    src: url("../assets/fonts/Roboto/Roboto-Italic.ttf") format("opentype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Roboto-Light";
    src: url("../assets/fonts/Roboto/Roboto-Light.ttf") format("opentype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Roboto-LightItalic";
    src: url("../assets/fonts/Roboto/Roboto-LightItalic.ttf") format("opentype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Roboto-Medium";
    src: url("../assets/fonts/Roboto/Roboto-Medium.ttf") format("opentype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Roboto-MediumItalic";
    src: url("../assets/fonts/Roboto/Roboto-MediumItalic.ttf") format("opentype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Roboto-Regular";
    src: url("../assets/fonts/Roboto/Roboto-Regular.ttf") format("opentype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Roboto-Thin";
    src: url("../assets/fonts/Roboto/Roboto-Thin.ttf") format("opentype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Roboto-ThinItalic";
    src: url("../assets/fonts/Roboto/Roboto-ThinItalic.ttf") format("opentype");
    font-weight: normal;
    font-style: normal;
}

:root {
    font-size: 12px;

    /** Fonts **/
    --ion-font-family-black: "Roboto-Black";
    --ion-font-family-black-italic: "Roboto-BlackItalic";
    --ion-font-family-bold: "Roboto-Bold";
    --ion-font-family-bold-italic: "Roboto-BoldItalic";
    --ion-font-family-italic: "Roboto-Italic";
    --ion-font-family-light: "Roboto-Light";
    --ion-font-family-light-italic: "Roboto-LightItalic";
    --ion-font-family-medium: "Roboto-Medium";
    --ion-font-family-medium-italic: "Roboto-MediumItalic";
    --ion-font-family-regular: "Roboto-Regular";
    --ion-font-family-thin: "Roboto-Thin";
    --ion-font-family-thin-italic: "Roboto-ThinItalic";
}