/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* Mixins */
@import "src/theme/mixins.scss";

/* Variables */
@import "src/theme/variables.scss";
@import "src/theme/fonts.scss";


html,
body {
  height: 100%;
}

body {
  font-family: var(--ion-font-family-regular);
}

.ion-page {
  overflow: auto;
  justify-content: flex-start;
}

.breadcrumbs {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #707070;

  strong {
    font-weight: 600;
    color: #C21B17;
  }

  // para mobile y table
  @media (max-width: 840px) {
    position: absolute;
  }
}

.page-container {
  min-height: 50vh;
  height: auto !important;
  margin: 37px 60px 100px 60px;
  display: inline-table;

  // para mobile y table
  @media (max-width: 840px) {
    margin: 17px 20px 100px 20px;
  }
}

.pre-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.page-title {
  text-align: left;
  font-style: normal;
  font-size: 30px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0.7px;
  color: var(--ion-color-primary);
  margin-top: 30px;

  // para mobile y table
  @media (max-width: 840px) {
    font-size: 21px;
    line-height: 30px;
    text-align: left;
  }
}

.description {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 5px;
  color: var(--ion-color-gray-25);
}

.d-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.d-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.d-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
}

.d-around {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.d-evenly {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}

.d-flex {
  display: flex;
}

.d-block {
  display: block;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.fade-in {
  animation: fadeIn linear 1s;
  -webkit-animation: fadeIn linear 1s;
  -moz-animation: fadeIn linear 1s;
  -o-animation: fadeIn linear 1s;
  -ms-animation: fadeIn linear 1s;
}

ion-alert {
  .alert-wrapper {
    .alert-head {
      display: flex;
      justify-content: center;

      .alert-title {
        color: var(--ion-color-primary);
        font-family: var(--ion-font-family-regular);
        font-style: normal;
        font-weight: 700;
        font-size: 25px;
        line-height: 29px;
        text-align: center;
      }
    }

    .alert-message {
      color: #6F7072;
      font-family: var(--ion-font-family-regular);
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
    }

    .alert-button-group {
      display: flex;
      flex-direction: column;
      align-items: center;

      .alert-button {
        height: 42px;
        width: 90%;
        color: var(--ion-color-primary);
        border-radius: 25px;
        font-family: var(--ion-font-family-regular);
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        margin: 4px;

        &.alert-button-role-confirm {
          color: var(--ion-color-white);
          background: var(--ion-color-light-reddish);
        }

        &.alert-button-role-confirm:hover {
          background: var(--ion-color-primary);
          box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.2);
        }

        &.alert-button-role-cancel {
          color: #2C343C;
          background: var(--ion-color-white);
          ;
          line-height: 26px;
        }

        &.alert-button-role-cancel:hover {
          box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.2);
        }

        .alert-button-inner {
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}

ion-toast {
  border-radius: 5px;
  font-family: var(--ion-font-family-regular);
  border-left: 1px solid var(--ion-color-gray-20);
  font-size: 15px;
  --box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.5);

  &.success-toast {
    --color: #3D915E;
    --background: #B9DAAF;
  }

  &.error-toast {
    --color: #B74849;
    --background: #FFC4C6;
  }

  &.warning-toast {
    --color: #DF6744;
    --background: #EDD3C8;
  }

  &.info-toast {
    --color: #6EA7BC;
    --background: #E9F1F3;
  }
}

//Placeholder de los inputs en cursiva
::-webkit-input-placeholder {
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}

:-moz-placeholder {
  font-style: italic;
}

::-moz-placeholder {
  font-style: italic;
}

:-ms-input-placeholder {
  font-style: italic;
}

.mat-dialog-container {
  border-radius: 20px;
  padding: 50px;
}

app-contact {
  height: auto;
  max-height: 100vh;
}

.mat-placeholder-required {
  color: var(--ion-color-primary);
}

.cdk-overlay-pane {
  overflow: auto;
}